<template>
  <div v-if="assignedQuestion === 'yes'">
    <v-form @submit.prevent="submitResponse">
      <h2 class="mb-3">Critical Thinking Question</h2>

      <p class="mb-5">
        This is a "Critical Thinking" question. You will be scored more on your thought process than
        your answer, so the key is to write, write, write. Explain the questions you have and how
        you would answer them. Who would you look to for answers (sources)? Who would you believe?
        Make the connections you can make, but most of all explain your thought process.
      </p>

      <p class="mb-5">
        Two or three paragraphs should be sufficient; one or two sentences will surely not be
        sufficient. This question could take up to a half-hour to answer completely.
      </p>

      <p class="mb-5">
        <b>Context:</b> Human blood must have a pH between 7.35 and 7.45 – any deviation from this
        value causes serious illness and death. Blood contains a buffer consisting of carbonic acid
        <chemical-latex content="(H2CO3)" /> and bicarbonate anions
        <chemical-latex content="(H2CO3)\text{.}" /> It forms an equilibrium shown below:
      </p>

      <p class="pl-6 mb-5">
        <chemical-latex content="H3O+ + HCO3^− <=> H2CO3 + H2O" />
      </p>

      <p class="mb-5">
        The carbonic acid forms as a result of <chemical-latex content="CO2" /> being in equilibrium
        with water:
      </p>

      <p class="pl-6 mb-5">
        <chemical-latex content="H2CO3 <=> H2O + CO2" />
      </p>

      <p class="mb-5">
        When we breathe, we inhale oxygen and exhale carbon dioxide. The bicarbonate anion is
        produced by the kidneys.
      </p>

      <p class="mb-5">
        The figure shown below depicts the buffer curve for the pH of blood. Imagine that you are an
        emergency medicine doctor. You have a patient with respiratory alkalosis, which occurs when
        the lungs exhale too much <chemical-latex content="CO2\text{.}" />
      </p>

      <p class="pl-4 mb-6">
        <v-img :src="imageName" style="max-width: 513px" />
      </p>

      <p class="mb-3">Address each of the following questions in the space provided below:</p>

      <ul class="mb-5">
        <li>What would happen to the pH of your patient's blood?</li>
        <li>
          If the pH of someone's blood increases above 7.8, they would die. What % of the buffer
          would have to be in the bicarbonate form to shift from a normal pH to one that would
          endanger your patient's life?
        </li>
        <li>What could you suggest to help your patient?</li>
      </ul>

      <p class="mb-0">
        <s-textarea v-model="inputs.studentAnswer" outlined />
      </p>
    </v-form>
  </div>

  <div v-else>
    <h3 style="color: #808080; text-align: center" class="mt-13">
      Sorry, but this is not one of your 3 assigned questions.
    </h3>
  </div>
</template>

<script>
import DynamicQuestionMixin from '../../mixins/dynamic-question';
import STextarea from '@/common/components/STextarea.vue';
import StembleLatex from '@/tasks/components/StembleLatex';
import ChemicalLatex from '@/tasks/components/displayers/ChemicalLatex.vue';

export default {
  name: 'OleMiss116_PL11_Q4',
  components: {ChemicalLatex, STextarea, StembleLatex},
  mixins: [DynamicQuestionMixin()],
  data() {
    return {
      inputs: {
        studentAnswer: null,
      },
    };
  },
  computed: {
    imageName() {
      return '/img/assignments/pHversusAcetateBufferComposition.png';
    },
    lastName() {
      return this.$auth.user.lastName.toUpperCase();
    },
    assignedQuestion() {
      if (
        !this.lastName.startsWith('A') &&
        !this.lastName.startsWith('B') &&
        !this.lastName.startsWith('C') &&
        !this.lastName.startsWith('D') &&
        !this.lastName.startsWith('E') &&
        !this.lastName.startsWith('F') &&
        !this.lastName.startsWith('G') &&
        !this.lastName.startsWith('H') &&
        !this.lastName.startsWith('I') &&
        // !this.lastName.startsWith('J') &&
        // !this.lastName.startsWith('K') &&
        // !this.lastName.startsWith('L') &&
        // !this.lastName.startsWith('M') &&
        // !this.lastName.startsWith('N') &&
        // !this.lastName.startsWith('O') &&
        // !this.lastName.startsWith('P') &&
        // !this.lastName.startsWith('Q') &&
        // !this.lastName.startsWith('R') &&
        !this.lastName.startsWith('S') &&
        !this.lastName.startsWith('T') &&
        !this.lastName.startsWith('U') &&
        !this.lastName.startsWith('V') &&
        !this.lastName.startsWith('W') &&
        !this.lastName.startsWith('X') &&
        !this.lastName.startsWith('Y') &&
        !this.lastName.startsWith('Z')
      ) {
        return 'yes';
      } else {
        return 'no';
      }
    },
  },
};
</script>
